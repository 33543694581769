import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import ProductCarouselComponent from "../../components/ProductCarouselComponent";
import CategoryCardComponent from "../../components/CategoryCardComponent";
import { useEffect, useState } from "react";
import MetaComponent from "../../components/MetaComponent";
import 'bootstrap/dist/css/bootstrap.css'; 
import Carousel from 'react-bootstrap/Carousel'; 
import SwiperCore, { Autoplay, EffectCoverflow, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "../../App.css";

import { useMediaQuery } from 'react-responsive';
import MediaQuery from 'react-responsive'

SwiperCore.use([EffectCoverflow, Autoplay, Pagination]);

const HomePageComponent = ({ categories, getBestsellers }) => {
  
    const [mainCategories, setMainCategories] = useState([]);
    const [bestSellers, setBestsellers] = useState([]);
    const [error, setError] = useState('');

    const config = require("../../constants/constants.js")

    // 5 per 1024
    // 2 per tablet
    // 1 per tel
    let numCol1 = 5;

    const isMobile = useMediaQuery({ query: '(min-width: 576px)' });
    const isTablet = useMediaQuery({ query: '(min-width: 768px)' });
    const isPC = useMediaQuery({ query: '(min-width: 992px)' });
    let numCol;
    if (isMobile===true) numCol=1;
    if (isTablet===true) numCol=2;
    if (isPC===true) numCol=5;

    console.log({isMobile});
    console.log({isTablet});
    console.log({isPC});


    useEffect(() => {
        getBestsellers()
        .then((data) => {
            setBestsellers(data);
        })
        .catch((er) => {
            setError(er.response.data.message ? er.response.data.message : er.response.data)
           console.log(er.response.data.message ? er.response.data.message : er.response.data) 
        });
        setMainCategories((cat) => categories.filter((item) => !item.name.includes("/")));
    }, [categories])

  return (
    <>
    <MetaComponent />

    <div className="main-swiper">
      <Swiper
        effect={"coverflow"}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        grabCursor={true}
        centeredSlides={false}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 0,
          modifier: 1,
          slideShadows: false,
        }}
        pagination={true}
        className="mySwiper"
      >
        {/* using array */}
        {config.CUSTOMER_SLIDE_URL_ARRAY.map((img, i) => {
          return (
            <SwiperSlide key={i}>
              <img src={img} alt="" />
            </SwiperSlide>
          );
        })}

      </Swiper>

    </div>

    <Container>   

        <Row
        g-0
        className="pt-2 pt-md-5 w-100 px-4 px-xl-0 position-relative"
        >
          <Col
            xs={{ order: 2 }}
            md={{ size: 4, order: 1 }}
            tag="aside"
            className="pb-5 mb-5 pb-md-0 mb-md-0 mx-auto mx-md-0"
          >      
            <Carousel> 
              <Carousel.Item interval={2500}> 
                <img 
                  className="d-block w-100"
                  src={config.PAGE_LANDING_SLIDE_1_URL}
                  alt="immmagine del prodotto"
                /> 
                <Carousel.Caption> 
                  <h3>{config.PAGE_LANDING_SLIDE_1_TITOLO}</h3> 
                  <p>{config.PAGE_LANDING_SLIDE_1_SOTTOTITOLO}</p> 
                </Carousel.Caption> 
              </Carousel.Item> 
              <Carousel.Item interval={2500}> 
                <img 
                  className="d-block w-100"
                  src={config.PAGE_LANDING_SLIDE_2_URL}
                  alt="immmagine del prodotto"
                /> 
                <Carousel.Caption> 
                  <h3>{config.PAGE_LANDING_SLIDE_2_TITOLO}</h3> 
                  <p>{config.PAGE_LANDING_SLIDE_2_SOTTOTITOLO}</p> 
                </Carousel.Caption> 
              </Carousel.Item> 
              <Carousel.Item interval={2500}> 
                <img 
                  className="d-block w-100"
                  src={config.PAGE_LANDING_SLIDE_3_URL}
                  alt="immmagine del prodotto"
                /> 
                <Carousel.Caption> 
                  <h3>{config.PAGE_LANDING_SLIDE_3_TITOLO}</h3> 
                  <p>{config.PAGE_LANDING_SLIDE_3_SOTTOTITOLO}</p> 
                </Carousel.Caption> 
              </Carousel.Item> 
            </Carousel> 

          </Col>

          <Col
            xs={{ order: 1 }}
            md={{ size: 7, offset: 1 }}
            tag="section"
            className="py-5 mb-5 py-md-0 mb-md-0"
          >
              <h1>{config.CUSTOMER}</h1>

              <Form.Group className="mb-3" controlId="validationCustom01" >
                <Form.Label><em>&nbsp;<b>{config.PAGE_LANDING_TITOLO}</b></em></Form.Label>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  {config.PAGE_LANDING_TESTO} 
                </Form.Label>
              </Form.Group>

          </Col>
        </Row>


      {/* 

        <MediaQuery minWidth={1025}>    
          <Row xs={5} md={5} className="g-5 mt-5">
            {mainCategories.map((category, idx) => (
              <CategoryCardComponent key={idx} category={category} idx={idx} />
            ))}
          </Row>
        </MediaQuery>    
        <MediaQuery maxWidth={1024}>    
          <Row xs={1} md={1} className="g-1 mt-1">
            {mainCategories.map((category, idx) => (
              <CategoryCardComponent key={idx} category={category} idx={idx} />
            ))}
          </Row>
        </MediaQuery>    
        <MediaQuery maxWidth={375}>    
          <Row xs={1} md={1} className="g-1 mt-1">
            {mainCategories.map((category, idx) => (
              <CategoryCardComponent key={idx} category={category} idx={idx} />
            ))}
          </Row>
        </MediaQuery>    

       */}

        <Row className="mt-5 justify-content-md-center">
          <Col md={6}>
          </Col>
        </Row>

      </Container>


      {/* 
        <ProductCarouselComponent bestSellers={bestSellers} />
      */}

    </>
  );
};

export default HomePageComponent;
