//const DB_URI = "mongodb+srv://xxx:xxx@cluster0.xxx.mongodb.net/?retryWrites=true&w=majority";
//const API = process.env.NODE_ENV === "production"
//    ? "https://xxx.vercel.app/api"
//    : "http://localhost:3000/api";

//const GOOGLE_CLIENT_ID = "xxx-xxx.apps.googleusercontent.com";
//const GOOGLE_CLIENT_SECRET = "xxx";

// identificazione mpmproject-it
const TITLE_PRODUCT = "MPM PROJECT";

// prelievo IMMAGINI SITO da www.shoppingclick.it  (non utilizzato)
// =>/home/shouser10/htdocs/www.shoppingclick.it/mpmproject-it/storage-images/site-mpmproject-it
const URL_IMAGES_SITE = "https://www.shoppingclick.it/mpmproject-it/storage-images/site-mpmproject-it/";

// identificazione cliente
//    CUSTOMER-0001 = CUSTOMER_CODE = "0001 
const CUSTOMER = "MPM PROJECT";
const CUSTOMER_CODE = "0001";
const CUSTOMER_VIA = "20030 Brembate";
const CUSTOMER_CITTA = "info@mpmproject.it";
const CUSTOMER_EMAIL = "www.mpmproject.it";

let SITE_STYLE_ACTIVE="B"
//const ENABLE_SWITCH_STILE = true;
const ENABLE_SWITCH_STILE = false;


// slider LandingPage
// slider LandingPage
// slider LandingPage

const CUSTOMER_SLIDE_URL_ARRAY = [
  "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-0001/landing-slider/slide-shop01.png",
  "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-0001/landing-slider/slide-shop02.png",
  "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-0001/landing-slider/slide-shop03.png",
  "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-0001/landing-slider/slide-shop04.png",
  "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-0001/landing-slider/slide-shop05.png",
  "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-0001/landing-slider/slide-shop06.png",
  "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-0001/landing-slider/slide-shop06.png",
  "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-0001/landing-slider/slide-studio01.png",
  "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-0001/landing-slider/slide-studio02.png",
  "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-0001/landing-slider/slide-studio03.png",
  "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-0001/landing-slider/slide-studio04.png",
];

// pagine di Menu
// pagine di Menu
// pagine di Menu

const PAGE_LANDING_TITOLO = "seguiamo Clienti ed Iniziative di rilievo nazionale"
const PAGE_LANDING_TESTO = "Siamo convinti che la professione debba essere sempre e costantemente al passo con le nuove tecnologie.  \
Crediamo nella costante crescita delle Professionalità. Riteniamo fondamentale il continuo aggiornamento nelle Risorse del nostro Team. \
Le conoscenze offerte dal nostro Team non sono seconde a nessuno. Il nostro Team di ricerca e sviluppo lavora costantemente per rilevare le novità e le migliori soluzioni.";
const PAGE_LANDING_SLIDE_1_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide25.jpg";
const PAGE_LANDING_SLIDE_1_TITOLO = "vantiamo Clienti di spicco";
const PAGE_LANDING_SLIDE_1_SOTTOTITOLO = "per garantirti la massima professionalità";
const PAGE_LANDING_SLIDE_2_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide62.jpg";
const PAGE_LANDING_SLIDE_2_TITOLO = "vantiamo Clienti di spicco";
const PAGE_LANDING_SLIDE_2_SOTTOTITOLO = "per garantirti la massima professionalità";
const PAGE_LANDING_SLIDE_3_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide26.jpg";
const PAGE_LANDING_SLIDE_3_TITOLO = "vantiamo Clienti di spicco";
const PAGE_LANDING_SLIDE_3_SOTTOTITOLO = "per garantirti la massima professionalità";

const PAGE_LOGIN_TITOLO = "la Professionalità al tuo servizio";
const PAGE_LOGIN_TESTO = "Tutti i giorni con passione ci aggiorniamo e ci prepariamo per gestire al meglio le tue esigenze.  \
Assicuriamo la migliore assistenza perchè la nostra Storia vuole continuità. \
Il nostro Team è composto dai migliori Professionisti in grado di seguirti in tutte le fasi. \
Abbiamo a cuore le tue problematiche e garantiamo la migliore soluzione possibile.";
const PAGE_LOGIN_SLIDE_1_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide25.jpg";
const PAGE_LOGIN_SLIDE_1_TITOLO = "la nostra esperienza al tuo servizio";
const PAGE_LOGIN_SLIDE_1_SOTTOTITOLO = "un Team qualificato a tua disposizione";
const PAGE_LOGIN_SLIDE_2_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide26.jpg";
const PAGE_LOGIN_SLIDE_2_TITOLO = "sceglierai ogni cosa";
const PAGE_LOGIN_SLIDE_2_SOTTOTITOLO = "selezioni il tema ed ogni elemento grafico";
const PAGE_LOGIN_SLIDE_3_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide27.jpg";
const PAGE_LOGIN_SLIDE_3_TITOLO = "continuo aggiornamento";
const PAGE_LOGIN_SLIDE_3_SOTTOTITOLO = "potrai scegliere sempre tra stili nuovi";

const PAGE_REGISTRA_TITOLO = "dove facciamo la differenza";
const PAGE_REGISTRA_TESTO = "Il nostro lavoro deve restare costantemente al passo con le nuove tecnologie.  \
Beneficiamo di modalità di comunicazione avanzate in modo da andare da seguire coloro che hanno poco tempo da dedicarci. \
MPM PROJECT, forte della ventennale esperienza dei Professionisti che lo compongono, ti offre la migliore competenza ai tuoi problemi. \
Le conoscenze offerte dal nostro team non sono seconde a nessuno. Il nostro team di ricerca e sviluppo lavora costantemente per raggiungere le migliori soluzioni";
const PAGE_REGISTRA_SLIDE_1_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide44.jpg";
const PAGE_REGISTRA_SLIDE_1_TITOLO = "la nostra esperienza al tuo servizio";
const PAGE_REGISTRA_SLIDE_1_SOTTOTITOLO = "un Team qualificato a tua disposizione";
const PAGE_REGISTRA_SLIDE_2_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide43.jpg";
const PAGE_REGISTRA_SLIDE_2_TITOLO = "massima professionalità";
const PAGE_REGISTRA_SLIDE_2_SOTTOTITOLO = "selezioni il tema ed ogni elemento grafico";
const PAGE_REGISTRA_SLIDE_3_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide23.jpg";
const PAGE_REGISTRA_SLIDE_3_TITOLO = "sceglierai ogni cosa";
const PAGE_REGISTRA_SLIDE_3_SOTTOTITOLO = "potrai scegliere sempre tra stili nuovi";

const PAGE_HOME_TITOLO = "La nostra passione, la nostra esperienza";
const PAGE_HOME_TESTO = "Soluzioni personalizzate: soluzioni progettate per soddisfare le Tue esigenze aziendali, garantendo efficienza e produttività. \
Integrazione perfetta: integriamo perfettamente i nostri prodotti con i tuoi sistemi esistenti, riducendo al minimo le interruzioni. \
Offriamo servizi completi di valutazione. Ci affiancheremo a Te per per garantirti le scelte più opportune e di successo";

const PAGE_HOME_SLIDE_1_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide1.jpg";
const PAGE_HOME_SLIDE_1_TITOLO = "la nostra competenza al tuo servizio";
const PAGE_HOME_SLIDE_1_SOTTOTITOLO = "un Team qualificato a tua disposizione";
const PAGE_HOME_SLIDE_2_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide21.jpg";
const PAGE_HOME_SLIDE_2_TITOLO = "sceglierai ogni cosa";
const PAGE_HOME_SLIDE_2_SOTTOTITOLO = "selezioni il tema ed ogni elemento grafico";
const PAGE_HOME_SLIDE_3_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide15.jpg";
const PAGE_HOME_SLIDE_3_TITOLO = "la nostra competenza al tuo servizio";
const PAGE_HOME_SLIDE_3_SOTTOTITOLO = "investiamo costantemente sulla formazione del nostro Team";

const PAGE_CHISIAMO_TITOLO = "La nostra storia";
const PAGE_CHISIAMO_TESTO = "Da più di 20 anni creiamo soluzioni software chiavi in mano. Offriamo assistenza e Software di successo commisurato alla nostra dedizione.\
Vantiamo una clientela di assoluto rilievo e il continuo investimento sulla formazione ti garantisce il miglior esito ai tuoi problemi. \
Il nostro team ha una vasta conoscenza del settore. Il nostro obiettivo è semplice: fornire ai nostri clienti consulenza di altissima qualità ai prezzi più competitivi. \
Le conoscenze offerte dal nostro team non sono seconde a nessuno. Il nostro team di ricerca e sviluppo lavora costantemente per rilevare le novità e le migliori soluzioni.";
const PAGE_CHISIAMO_SLIDE_1_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide17.jpg";
const PAGE_CHISIAMO_SLIDE_1_TITOLO = "la migliore competenza";
const PAGE_CHISIAMO_SLIDE_1_SOTTOTITOLO = "investiamo costantemente sulla formazione del nostro Team";
const PAGE_CHISIAMO_SLIDE_2_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide20.jpg";
const PAGE_CHISIAMO_SLIDE_2_TITOLO = "professionalità";
const PAGE_CHISIAMO_SLIDE_2_SOTTOTITOLO = "massima competenza al tuo servizio";
const PAGE_CHISIAMO_SLIDE_3_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide21.jpg";
const PAGE_CHISIAMO_SLIDE_3_TITOLO = "gentilezza";
const PAGE_CHISIAMO_SLIDE_3_SOTTOTITOLO = "il nostro Team ti aspetta per rispondere al meglio alle tue richieste";

const PAGE_SITI_TITOLO = "Siti all'avanguardia per pc, tablet e telefoni";
const PAGE_SITI_TESTO = "Siti responsive ed interattivi. Veri e propri PORTALI che offrono servizi e ti consentono di modificare i contenuti, le immagini e il testo di ogni pagina. \
Accedi come Admin e modifichi quello che vuoi. Hosting su server sicuro, servizio di manutenzione incluso per un anno. \
Scegli il tuo stile tra quelli disponibili. Ogni due mesi ne mettiamo a disposizione uno nuovo. Ogni 3 mesi potrai attivarne uno diverso senza spendere nulla. ";
const PAGE_SITI_SLIDE_1_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/myidea-volantino1.png";
const PAGE_SITI_SLIDE_1_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_SITI_SLIDE_1_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";
const PAGE_SITI_SLIDE_2_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/myidea-volantino2.png";
const PAGE_SITI_SLIDE_2_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_SITI_SLIDE_2_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";
const PAGE_SITI_SLIDE_3_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/myidea-volantino3.png";
const PAGE_SITI_SLIDE_3_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_SITI_SLIDE_3_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";

const PAGE_SITIPREZZI_TITOLO = "Siti all'avanguardia per pc, tablet e telefoni";
const PAGE_SITIPREZZI_PRODOTTO1_DESCR = "MyStudio sitoWeb";
const PAGE_SITIPREZZI_PRODOTTO1_DESCR1 = " ";
const PAGE_SITIPREZZI_PRODOTTO1_PREZZO = "€ 446,00";
const PAGE_SITIPREZZI_PRODOTTO2_DESCR = "MyStudio sitoWeb";
const PAGE_SITIPREZZI_PRODOTTO2_DESCR1 = "+ 3 email";
const PAGE_SITIPREZZI_PRODOTTO2_PREZZO = "€ 609,00";
const PAGE_SITIPREZZI_PRODOTTO3_DESCR = "MyIdea sitoWeb";
const PAGE_SITIPREZZI_PRODOTTO3_DESCR1 = "";
const PAGE_SITIPREZZI_PRODOTTO3_PREZZO = "€ 426,00 ";
const PAGE_SITIPREZZI_PRODOTTO4_DESCR = "MyIdea sitoWeb";
const PAGE_SITIPREZZI_PRODOTTO4_DESCR1 = "+ 3 email";
const PAGE_SITIPREZZI_PRODOTTO4_PREZZO = "€ 589,00 ";
const PAGE_SITIPREZZI_TESTO = "Siti responsive ed interattivi. Veri e propri PORTALI che offrono servizi e ti consentono modifiche ai contenuti, immagini e testo. \
Accedi come Admin e modifichi quello che vuoi. \
Scegli il tuo stile tra quelli disponibili. Ogni due mesi ne troverai uno nuovo. Ogni 3 mesi potrai attivarne uno diverso senza spendere nulla.";
const PAGE_SITIPREZZI_TESTO1 = "Tutto al 50% dal secondo anno in poi";
const PAGE_SITIPREZZI_SLIDE_1_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/myidea-volantino1.png";
const PAGE_SITIPREZZI_SLIDE_1_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_SITIPREZZI_SLIDE_1_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";
const PAGE_SITIPREZZI_SLIDE_2_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/myidea-volantino2.png";
const PAGE_SITIPREZZI_SLIDE_2_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_SITIPREZZI_SLIDE_2_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";
const PAGE_SITIPREZZI_SLIDE_3_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/myidea-volantino3.png";
const PAGE_SITIPREZZI_SLIDE_3_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_SITIPREZZI_SLIDE_3_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";

const PAGE_SITISTILI_TITOLO = "Stili disponibili per il tuo Sito";
const PAGE_SITISTILI_PRODOTTO1_NOME = "CLASSIC";
const PAGE_SITISTILI_PRODOTTO1_DESCR = "Stile Classic";
const PAGE_SITISTILI_PRODOTTO2_NOME = "MODERN";
const PAGE_SITISTILI_PRODOTTO2_DESCR = "Stile Modern";
const PAGE_SITISTILI_PRODOTTO3_NOME = "IMPACT";
const PAGE_SITISTILI_PRODOTTO3_DESCR = "Stile Impact";
const PAGE_SITISTILI_PRODOTTO4_NOME = "SUMMER";
const PAGE_SITISTILI_PRODOTTO4_DESCR = "Stile Summer";
const PAGE_SITISTILI_TESTO = "Sito Web responsive ed interattivo. Puoi applicare lo stile che preferisci, a tua scelta. Noi rendiamo disponibile un nuovo STILE ogni 2 mesi. \
Tu puoi variare lo STILE del tuo SitoWeb ogni 3 mesi, senza pagare nulla.";
const PAGE_SITISTILI_TESTO1 = "La tua immagine digitale può evolversi con te"
const PAGE_SITISTILI_SLIDE_1_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/myidea-volantino1.png";
const PAGE_SITISTILI_SLIDE_1_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_SITISTILI_SLIDE_1_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";
const PAGE_SITISTILI_SLIDE_2_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/myidea-volantino2.png";
const PAGE_SITISTILI_SLIDE_2_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_SITISTILI_SLIDE_2_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";
const PAGE_SITISTILI_SLIDE_3_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/myidea-volantino3.png";
const PAGE_SITISTILI_SLIDE_3_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_SITISTILI_SLIDE_3_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";       

const PAGE_STUDIO_TITOLO = "Siti all'avanguardia per lo Studio Professionale";
const PAGE_STUDIO_PRODOTTO1_DESCR = "MyStudio sitoWeb";
const PAGE_STUDIO_PRODOTTO1_DESCR1 = " ";
const PAGE_STUDIO_PRODOTTO1_PREZZO = "€ 446,00";
const PAGE_STUDIO_PRODOTTO2_DESCR = "MyStudio sitoWeb";
const PAGE_STUDIO_PRODOTTO2_DESCR1 = "+ 3 email";
const PAGE_STUDIO_PRODOTTO2_PREZZO = "€ 609,00";
const PAGE_STUDIO_PRODOTTO3_DESCR = "MyIdea sitoWeb";
const PAGE_STUDIO_PRODOTTO3_DESCR1 = "";
const PAGE_STUDIO_PRODOTTO3_PREZZO = "€ 426,00 ";
const PAGE_STUDIO_PRODOTTO4_DESCR = "MyIdea sitoWeb";
const PAGE_STUDIO_PRODOTTO4_DESCR1 = "+ 3 email";
const PAGE_STUDIO_PRODOTTO4_PREZZO = "€ 586,00 ";
const PAGE_STUDIO_TESTO = "Siti per Professionisti. Studiati per un utilizzo categoria servizi, prodotti. Veri e propri PORTALI che offrono servizi e ti consentono modifiche ai contenuti, immagini e testo. \
Accedi come Admin e modifichi quello che vuoi. \
Scegli il tuo stile tra quelli disponibili. Ogni due mesi ne troverai uno nuovo. Ogni 3 mesi potrai attivarne uno diverso senza spendere nulla.";
const PAGE_STUDIO_TESTO1 = "Tutto al 50% dal secondo anno in poi";
const PAGE_STUDIO_SLIDE_1_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/mystudio-volantino1.png";
const PAGE_STUDIO_SLIDE_1_TITOLO = "elenco dinamico delle categorie";
const PAGE_STUDIO_SLIDE_1_SOTTOTITOLO = "e approfondimento con foto e testi";
const PAGE_STUDIO_SLIDE_2_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/mystudio-volantino2.png";
const PAGE_STUDIO_SLIDE_2_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_STUDIO_SLIDE_2_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";
const PAGE_STUDIO_SLIDE_3_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/mystudio-volantino3.png";
const PAGE_STUDIO_SLIDE_3_TITOLO = "l'impatto visivo è la migliore strategia di vendita";
const PAGE_STUDIO_SLIDE_3_SOTTOTITOLO = "ad ogni prodotto/servizio potrai approfondire con immagini e descrizione";

const PAGE_STUDIOPREZZI_TITOLO = "Siti all'avanguardia per pc, tablet e telefoni";
const PAGE_STUDIOPREZZI_PRODOTTO1_DESCR = "MyStudio sitoWeb";
const PAGE_STUDIOPREZZI_PRODOTTO1_DESCR1 = " ";
const PAGE_STUDIOPREZZI_PRODOTTO1_PREZZO = "€ 446,00";
const PAGE_STUDIOPREZZI_PRODOTTO2_DESCR = "MyStudio sitoWeb";
const PAGE_STUDIOPREZZI_PRODOTTO2_DESCR1 = "+ 3 email";
const PAGE_STUDIOPREZZI_PRODOTTO2_PREZZO = "€ 606,00";
const PAGE_STUDIOPREZZI_PRODOTTO3_DESCR = "MyStudio sitoWeb";
const PAGE_STUDIOPREZZI_PRODOTTO3_DESCR1 = "+ clienti";
const PAGE_STUDIOPREZZI_PRODOTTO3_PREZZO = "€ 486,00 ";
const PAGE_STUDIOPREZZI_PRODOTTO4_DESCR = "MyStudio sitoWeb";
const PAGE_STUDIOPREZZI_PRODOTTO4_DESCR1 = "+ catalogo";
const PAGE_STUDIOPREZZI_PRODOTTO4_PREZZO = "€ 486,00 ";
const PAGE_STUDIOPREZZI_TESTO = "Siti responsive ed interattivi. Veri e propri PORTALI che offrono servizi e ti consentono modifiche ai contenuti, immagini e testo. \
Accedi come Admin e modifichi quello che vuoi. \
Scegli il tuo stile tra quelli disponibili. Ogni due mesi ne troverai uno nuovo. Ogni 3 mesi potrai attivarne uno diverso senza spendere nulla.";
const PAGE_STUDIOPREZZI_TESTO1 = "Tutto al 50% dal secondo anno in poi";
const PAGE_STUDIOPREZZI_SLIDE_1_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/mystudio-volantino1.png";
const PAGE_STUDIOPREZZI_SLIDE_1_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_STUDIOPREZZI_SLIDE_1_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";
const PAGE_STUDIOPREZZI_SLIDE_2_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/mystudio-volantino2.png";
const PAGE_STUDIOPREZZI_SLIDE_2_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_STUDIOPREZZI_SLIDE_2_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";
const PAGE_STUDIOPREZZI_SLIDE_3_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/mystudio-volantino3.png";
const PAGE_STUDIOPREZZI_SLIDE_3_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_STUDIOPREZZI_SLIDE_3_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";

const PAGE_STUDIOSTILI_TITOLO = "Stili disponibili per il tuo Sito";
const PAGE_STUDIOSTILI_PRODOTTO1_NOME = "CLASSIC";
const PAGE_STUDIOSTILI_PRODOTTO1_DESCR = "Stile Classic";
const PAGE_STUDIOSTILI_PRODOTTO2_NOME = "MODERN";
const PAGE_STUDIOSTILI_PRODOTTO2_DESCR = "Stile Modern";
const PAGE_STUDIOSTILI_PRODOTTO3_NOME = "IMPACT";
const PAGE_STUDIOSTILI_PRODOTTO3_DESCR = "Stile Impact";
const PAGE_STUDIOSTILI_PRODOTTO4_NOME = "SUMMER";
const PAGE_STUDIOSTILI_PRODOTTO4_DESCR = "Stile Summer";
const PAGE_STUDIOSTILI_TESTO = "Sito Web responsive ed interattivo. Puoi applicare lo stile che preferisci, a tua scelta. Noi rendiamo disponibile un nuovo STILE ogni 2 mesi. \
Tu puoi variare lo STILE del tuo SitoWeb ogni 3 mesi, senza pagare nulla.";
const PAGE_STUDIOSTILI_TESTO1 = "La tua immagine digitale può evolversi con te"
const PAGE_STUDIOSTILI_SLIDE_1_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/mystudio-volantino1.png";
const PAGE_STUDIOSTILI_SLIDE_1_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_STUDIOSTILI_SLIDE_1_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";
const PAGE_STUDIOSTILI_SLIDE_2_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/mystudio-volantino2.png";
const PAGE_STUDIOSTILI_SLIDE_2_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_STUDIOSTILI_SLIDE_2_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";
const PAGE_STUDIOSTILI_SLIDE_3_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/mystudio-volantino3.png";
const PAGE_STUDIOSTILI_SLIDE_3_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_STUDIOSTILI_SLIDE_3_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";

const PAGE_PLANNER_TITOLO = "Siti per Prenotazione/Appuntamenti per tutte le Risorse";
const PAGE_PLANNER_PRODOTTO1_DESCR = "MyPlanner sitoWeb";
const PAGE_PLANNER_PRODOTTO1_DESCR1 = " ";
const PAGE_PLANNER_PRODOTTO1_PREZZO = "€ 446,00";
const PAGE_PLANNER_PRODOTTO2_DESCR = "MyPlanner sitoWeb";
const PAGE_PLANNER_PRODOTTO2_DESCR1 = "+ 3 email";
const PAGE_PLANNER_PRODOTTO2_PREZZO = "€ 609,00";
const PAGE_PLANNER_PRODOTTO3_DESCR = "MyPlanner full";
const PAGE_PLANNER_PRODOTTO3_DESCR1 = "";
const PAGE_PLANNER_PRODOTTO3_PREZZO = "€ 476,00 ";
const PAGE_PLANNER_PRODOTTO4_DESCR = "MyPlanner full";
const PAGE_PLANNER_PRODOTTO4_DESCR1 = "+ 3 email";
const PAGE_PLANNER_PRODOTTO4_PREZZO = "€ 616,00 ";
const PAGE_PLANNER_TESTO = "Siti per Negozi e Aziende, per Professionisti. Studiati per un utilizzo categoria di servizi, e scelta prodotto. Gestione completa dell'Agenda di tutte le Risorse. \
Accedi come Admin e modifichi quello che vuoi. \
Scegli il tuo stile tra quelli disponibili. Ogni due mesi ne troverai uno nuovo. Ogni 3 mesi potrai attivarne uno diverso senza spendere nulla.";
const PAGE_PLANNER_TESTO1 = "Tutto al 50% dal secondo anno in poi";
const PAGE_PLANNER_SLIDE_1_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/mystudio-volantino1.png";
const PAGE_PLANNER_SLIDE_1_TITOLO = "elenco dinamico delle categorie";
const PAGE_PLANNER_SLIDE_1_SOTTOTITOLO = "e approfondimento con foto e testi";
const PAGE_PLANNER_SLIDE_2_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/mystudio-volantino2.png";
const PAGE_PLANNER_SLIDE_2_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_PLANNER_SLIDE_2_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";
const PAGE_PLANNER_SLIDE_3_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/mystudio-volantino3.png";
const PAGE_PLANNER_SLIDE_3_TITOLO = "l'impatto visivo è la migliore strategia di vendita";
const PAGE_PLANNER_SLIDE_3_SOTTOTITOLO = "ad ogni prodotto/servizio potrai approfondire con immagini e descrizione";

const PAGE_PLANNERPREZZI_TITOLO = "Siti all'avanguardia per pc, tablet e telefoni";
const PAGE_PLANNERPREZZI_PRODOTTO1_DESCR = "MyPlanner sitoWeb";
const PAGE_PLANNERPREZZI_PRODOTTO1_DESCR1 = " ";
const PAGE_PLANNERPREZZI_PRODOTTO1_PREZZO = "€ 890,00";
const PAGE_PLANNERPREZZI_PRODOTTO2_DESCR = "MyPlanner sitoWeb";
const PAGE_PLANNERPREZZI_PRODOTTO2_DESCR1 = "+ 3 email";
const PAGE_PLANNERPREZZI_PRODOTTO2_PREZZO = "€ 1050,00";
const PAGE_PLANNERPREZZI_PRODOTTO3_DESCR = "MyPlanner sitoWeb";
const PAGE_PLANNERPREZZI_PRODOTTO3_DESCR1 = "gest. clienti";
const PAGE_PLANNERPREZZI_PRODOTTO3_PREZZO = "incluso ";
const PAGE_PLANNERPREZZI_PRODOTTO4_DESCR = "MyPlanner sitoWeb";
const PAGE_PLANNERPREZZI_PRODOTTO4_DESCR1 = "gest. catalogo";
const PAGE_PLANNERPREZZI_PRODOTTO4_PREZZO = "incluso";
const PAGE_PLANNERPREZZI_TESTO = "Siti responsive ed interattivi. Veri e propri PORTALI che offrono servizi e ti consentono modifiche ai contenuti, immagini e testo. \
Accedi come Admin e modifichi quello che vuoi. \
Scegli il tuo stile tra quelli disponibili. Ogni due mesi ne troverai uno nuovo. Ogni 3 mesi potrai attivarne uno diverso senza spendere nulla.";
const PAGE_PLANNERPREZZI_TESTO1 = "Tutto al 50% dal secondo anno in poi";
const PAGE_PLANNERPREZZI_SLIDE_1_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/mystudio-volantino1.png";
const PAGE_PLANNERPREZZI_SLIDE_1_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_PLANNERPREZZI_SLIDE_1_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";
const PAGE_PLANNERPREZZI_SLIDE_2_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/mystudio-volantino2.png";
const PAGE_PLANNERPREZZI_SLIDE_2_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_PLANNERPREZZI_SLIDE_2_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";
const PAGE_PLANNERPREZZI_SLIDE_3_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/mystudio-volantino3.png";
const PAGE_PLANNERPREZZI_SLIDE_3_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_PLANNERPREZZI_SLIDE_3_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";

const PAGE_PLANNERSTILI_TITOLO = "Stili disponibili per il tuo Sito";
const PAGE_PLANNERSTILI_PRODOTTO1_NOME = "CLASSIC";
const PAGE_PLANNERSTILI_PRODOTTO1_DESCR = "Stile Classic";
const PAGE_PLANNERSTILI_PRODOTTO2_NOME = "MODERN";
const PAGE_PLANNERSTILI_PRODOTTO2_DESCR = "Stile Modern";
const PAGE_PLANNERSTILI_PRODOTTO3_NOME = "IMPACT";
const PAGE_PLANNERSTILI_PRODOTTO3_DESCR = "Stile Impact";
const PAGE_PLANNERSTILI_PRODOTTO4_NOME = "SUMMER";
const PAGE_PLANNERSTILI_PRODOTTO4_DESCR = "Stile Summer";
const PAGE_PLANNERSTILI_TESTO = "Sito Web responsive ed interattivo. Puoi applicare lo stile che preferisci, a tua scelta. Noi rendiamo disponibile un nuovo STILE ogni 2 mesi. \
Tu puoi variare lo STILE del tuo SitoWeb ogni 3 mesi, senza pagare nulla.";
const PAGE_PLANNERSTILI_TESTO1 = "La tua immagine digitale può evolversi con te"
const PAGE_PLANNERSTILI_SLIDE_1_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/mystudio-volantino1.png";
const PAGE_PLANNERSTILI_SLIDE_1_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_PLANNERSTILI_SLIDE_1_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";
const PAGE_PLANNERSTILI_SLIDE_2_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/mystudio-volantino2.png";
const PAGE_PLANNERSTILI_SLIDE_2_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_PLANNERSTILI_SLIDE_2_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";
const PAGE_PLANNERSTILI_SLIDE_3_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/mystudio-volantino3.png";
const PAGE_PLANNERSTILI_SLIDE_3_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_PLANNERSTILI_SLIDE_3_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";

const PAGE_HOTEL_TITOLO = "Siti all'avanguardia per Hote, B&B e Resort";
const PAGE_HOTEL_PRODOTTO1_DESCR = "MyPlace sitoWeb";
const PAGE_HOTEL_PRODOTTO1_DESCR1 = " ";
const PAGE_HOTEL_PRODOTTO1_PREZZO = "€ 459,00";
const PAGE_HOTEL_PRODOTTO2_DESCR = "MyPlace sitoWeb";
const PAGE_HOTEL_PRODOTTO2_DESCR1 = "+ 3 email";
const PAGE_HOTEL_PRODOTTO2_PREZZO = "€ 619,00";
const PAGE_HOTEL_PRODOTTO3_DESCR = "MyPlace sitoWeb";
const PAGE_HOTEL_PRODOTTO3_DESCR1 = "+ social";
const PAGE_HOTEL_PRODOTTO3_PREZZO = "€ 479,00 ";
const PAGE_HOTEL_PRODOTTO4_DESCR = "MyPlace sitoWeb";
const PAGE_HOTEL_PRODOTTO4_DESCR1 = "+ fornitori";
const PAGE_HOTEL_PRODOTTO4_PREZZO = "€ 479,00 ";
const PAGE_HOTEL_TESTO = "Siti per Hoteo e B&B. Studiati per la prenotazione di alloggi, e l'elenco di tutti i servizi offerti in una struttura aberghiera. Veri e propri PORTALI che offrono servizi e ti consentono modifiche ai contenuti, immagini e testo. \
Accedi come Admin e modifichi quello che vuoi. \
Scegli il tuo stile tra quelli disponibili. Ogni due mesi ne troverai uno nuovo. Ogni 3 mesi potrai attivarne uno diverso senza spendere nulla.";
const PAGE_HOTEL_TESTO1 = "Tutto al 50% dal secondo anno in poi";
const PAGE_HOTEL_SLIDE_1_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/myidea-volantino1.png";
const PAGE_HOTEL_SLIDE_1_TITOLO = "il modo migliore per descrivere alloggi e soluzioni";
const PAGE_HOTEL_SLIDE_1_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";
const PAGE_HOTEL_SLIDE_2_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/myidea-volantino2.png";
const PAGE_HOTEL_SLIDE_2_TITOLO = "l'elenco dei servizi stagionali ";
const PAGE_HOTEL_SLIDE_2_SOTTOTITOLO = "e tanto altro ancora...";
const PAGE_HOTEL_SLIDE_3_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/myidea-volantino3.png";
const PAGE_HOTEL_SLIDE_3_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_HOTEL_SLIDE_3_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";

const PAGE_HOTELPREZZI_TITOLO = "Siti all'avanguardia per pc, tablet e telefoni";
const PAGE_HOTELPREZZI_PRODOTTO1_DESCR = "MyPlace sitoWeb";
const PAGE_HOTELPREZZI_PRODOTTO1_DESCR1 = " ";
const PAGE_HOTELPREZZI_PRODOTTO1_PREZZO = "€ 459,00";
const PAGE_HOTELPREZZI_PRODOTTO2_DESCR = "MyPlace sitoWeb";
const PAGE_HOTELPREZZI_PRODOTTO2_DESCR1 = "+ 3 email";
const PAGE_HOTELPREZZI_PRODOTTO2_PREZZO = "€ 619,00";
const PAGE_HOTELPREZZI_PRODOTTO3_DESCR = "MyPlace sitoWeb";
const PAGE_HOTELPREZZI_PRODOTTO3_DESCR1 = "+ social";
const PAGE_HOTELPREZZI_PRODOTTO3_PREZZO = "€ 479,00 ";
const PAGE_HOTELPREZZI_PRODOTTO4_DESCR = "MyPlace sitoWeb";
const PAGE_HOTELPREZZI_PRODOTTO4_DESCR1 = "+ fornitori";
const PAGE_HOTELPREZZI_PRODOTTO4_PREZZO = "€ 479,00 ";
const PAGE_HOTELPREZZI_TESTO = "Siti responsive ed interattivi. Veri e propri PORTALI che offrono servizi e ti consentono modifiche ai contenuti, immagini e testo. \
Accedi come Admin e modifichi quello che vuoi. \
Scegli il tuo stile tra quelli disponibili. Ogni due mesi ne troverai uno nuovo. Ogni 3 mesi potrai attivarne uno diverso senza spendere nulla.";
const PAGE_HOTELPREZZI_TESTO1 = "Tutto al 50% dal secondo anno in poi";
const PAGE_HOTELPREZZI_SLIDE_1_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/myidea-volantino1.png";
const PAGE_HOTELPREZZI_SLIDE_1_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_HOTELPREZZI_SLIDE_1_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";
const PAGE_HOTELPREZZI_SLIDE_2_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/myidea-volantino2.png";
const PAGE_HOTELPREZZI_SLIDE_2_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_HOTELPREZZI_SLIDE_2_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";
const PAGE_HOTELPREZZI_SLIDE_3_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/myidea-volantino3.png";
const PAGE_HOTELPREZZI_SLIDE_3_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_HOTELPREZZI_SLIDE_3_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";

const PAGE_HOTELSTILI_TITOLO = "Stili disponibili per il tuo Sito";
const PAGE_HOTELSTILI_PRODOTTO1_NOME = "CLASSIC";
const PAGE_HOTELSTILI_PRODOTTO1_DESCR = "Stile Classic";
const PAGE_HOTELSTILI_PRODOTTO2_NOME = "MODERN";
const PAGE_HOTELSTILI_PRODOTTO2_DESCR = "Stile Modern";
const PAGE_HOTELSTILI_PRODOTTO3_NOME = "IMPACT";
const PAGE_HOTELSTILI_PRODOTTO3_DESCR = "Stile Impact";
const PAGE_HOTELSTILI_PRODOTTO4_NOME = "SUMMER";
const PAGE_HOTELSTILI_PRODOTTO4_DESCR = "Stile Summer";
const PAGE_HOTELSTILI_TESTO = "Sito Web responsive ed interattivo. Puoi applicare lo stile che preferisci, a tua scelta. Noi rendiamo disponibile un nuovo STILE ogni 2 mesi. \
Tu puoi variare lo STILE del tuo SitoWeb ogni 3 mesi, senza pagare nulla.";
const PAGE_HOTELSTILI_TESTO1 = "La tua immagine digitale può evolversi con te"
const PAGE_HOTELSTILI_SLIDE_1_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/myidea-volantino1.png";
const PAGE_HOTELSTILI_SLIDE_1_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_HOTELSTILI_SLIDE_1_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";
const PAGE_HOTELSTILI_SLIDE_2_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/myidea-volantino2.png";
const PAGE_HOTELSTILI_SLIDE_2_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_HOTELSTILI_SLIDE_2_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";
const PAGE_HOTELSTILI_SLIDE_3_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/myidea-volantino3.png";
const PAGE_HOTELSTILI_SLIDE_3_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_HOTELSTILI_SLIDE_3_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";

const PAGE_ECOMMERCE_TITOLO = "SMART ECONOMICO IMMEDIATO, creato con le tecnologie più recenti";
const PAGE_ECOMMERCE_TESTO = "Ecommerce innovativi che consentono alle aziende di emergere nell’era digitale. \
Potenzia il tuo business con ecommerce all'avanguardia e su misura per le tue esigenze \
adatto a NEGOZI AZIENDE e MULTISTORE. \
Con MPM PROJECT avrai ecommerce per la Business Acceleration. \
Soluzioni personalizzate e progettate per soddisfare le Tue esigenze aziendali, garantendo efficienza e produttività. \
Integrazione perfetta: integriamo perfettamente l'ecommerce con i tuoi sistemi esistenti, riducendo al minimo le interruzioni. ";
const PAGE_ECOMMERCE_SLIDE_1_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/myshop-volantino2.png";
const PAGE_ECOMMERCE_SLIDE_1_TITOLO = "il nostro continuo aggiornamento ";
const PAGE_ECOMMERCE_SLIDE_1_SOTTOTITOLO = "ti garantirà la miglior assistenza";
const PAGE_ECOMMERCE_SLIDE_2_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/myshop-volantino3.png";
const PAGE_ECOMMERCE_SLIDE_2_TITOLO = "il nostro continuo aggiornamento ";
const PAGE_ECOMMERCE_SLIDE_2_SOTTOTITOLO = "ti garantirà la miglior assistenza";
const PAGE_ECOMMERCE_SLIDE_3_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/myshop-volantino4.png";
const PAGE_ECOMMERCE_SLIDE_3_TITOLO = "il nostro continuo aggiornamento ";
const PAGE_ECOMMERCE_SLIDE_3_SOTTOTITOLO = "ti garantirà la miglior assistenza";

const PAGE_ECOMMERCEPREZZI_TITOLO = "Soluzioni su misura";
const PAGE_ECOMMERCEPREZZI_PRODOTTO1_DESCR = "MyShop BASE";
const PAGE_ECOMMERCEPREZZI_PRODOTTO1_PREZZO = "€ 799,00";
const PAGE_ECOMMERCEPREZZI_PRODOTTO2_DESCR = "MyShop PREMIUM";
const PAGE_ECOMMERCEPREZZI_PRODOTTO2_PREZZO = "€ 1149,00";
const PAGE_ECOMMERCEPREZZI_PRODOTTO3_DESCR = "MyShop GOLD";
const PAGE_ECOMMERCEPREZZI_PRODOTTO3_PREZZO = "€ 1359,00 ";
const PAGE_ECOMMERCEPREZZI_TESTO = "Creazione e manutenzione annuale. Prodotti illimitati, filtri per categorie, fasce di prezzo, recensioni e nome prodotto. Assistenza TOP.";
const PAGE_ECOMMERCEPREZZI_TESTO1 = "Tutto al 50% dal secondo anno in poi";
const PAGE_ECOMMERCEPREZZI_SLIDE_1_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/myshop-volantino5.png";
const PAGE_ECOMMERCEPREZZI_SLIDE_1_TITOLO = "il nostro continuo aggiornamento ";
const PAGE_ECOMMERCEPREZZI_SLIDE_1_SOTTOTITOLO = "ti garantirà la miglior assistenza";
const PAGE_ECOMMERCEPREZZI_SLIDE_2_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/myshop-volantino6.png";
const PAGE_ECOMMERCEPREZZI_SLIDE_2_TITOLO = "il nostro continuo aggiornamento ";
const PAGE_ECOMMERCEPREZZI_SLIDE_2_SOTTOTITOLO = "ti garantirà la miglior assistenza";
const PAGE_ECOMMERCEPREZZI_SLIDE_3_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/myshop-volantino7.png";
const PAGE_ECOMMERCEPREZZI_SLIDE_3_TITOLO = "il nostro continuo aggiornamento ";
const PAGE_ECOMMERCEPREZZI_SLIDE_3_SOTTOTITOLO = "ti garantirà la miglior assistenza";

const PAGE_ECOMMERCESTILI_TITOLO = "Stili disponibili per il tuo ecommerce";
const PAGE_ECOMMERCESTILI_PRODOTTO1_NOME = "CLASSIC";
const PAGE_ECOMMERCESTILI_PRODOTTO1_DESCR = "Stile Classic";
const PAGE_ECOMMERCESTILI_PRODOTTO2_NOME = "ART";
const PAGE_ECOMMERCESTILI_PRODOTTO2_DESCR = "Stile Art";
const PAGE_ECOMMERCESTILI_PRODOTTO3_NOME = "JAZZ";
const PAGE_ECOMMERCESTILI_PRODOTTO3_DESCR = "Stile Jazz";
const PAGE_ECOMMERCESTILI_TESTO = "Il tuo Ecommerce responsive ed interattivo. Puoi applicare lo stile che preferisci, a tua scelta. Noi rendiamo disponibile un nuovo STILE ogni 2 mesi. \
Tu puoi variare lo STILE del tuo ecommerce ogni 3 mesi, senza pagare nulla. ";
const PAGE_ECOMMERCESTILI_TESTO1 = "La tua immagine digitale può evolversi con te"
const PAGE_ECOMMERCESTILI_SLIDE_1_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/myshop-volantino1.png";
const PAGE_ECOMMERCESTILI_SLIDE_1_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_ECOMMERCESTILI_SLIDE_1_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";
const PAGE_ECOMMERCESTILI_SLIDE_2_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/myshop-volantino2.png";
const PAGE_ECOMMERCESTILI_SLIDE_2_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_ECOMMERCESTILI_SLIDE_2_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";
const PAGE_ECOMMERCESTILI_SLIDE_3_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/myshop-volantino3.png";
const PAGE_ECOMMERCESTILI_SLIDE_3_TITOLO = "strumenti moderni al tuo servizio";
const PAGE_ECOMMERCESTILI_SLIDE_3_SOTTOTITOLO = "disponiamo delle più moderne soluzioni";

const PAGE_CONTATTI_TITOLO = "Contatti";
const PAGE_CONTATTI_TESTO = "saremo lieti di risponderti al più presto";
const PAGE_CONTATTI_SLIDE_1_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide1.jpg";
const PAGE_CONTATTI_SLIDE_1_TITOLO = "la cortesia prima di tutto";
const PAGE_CONTATTI_SLIDE_1_SOTTOTITOLO = "la nostra esperienza a disposizione per la tua scelta";
const PAGE_CONTATTI_SLIDE_2_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide15.jpg";
const PAGE_CONTATTI_SLIDE_2_TITOLO = "esperienza per la giusta scelta";
const PAGE_CONTATTI_SLIDE_2_SOTTOTITOLO = "il nostro Team ti aiuterà a personalizzare il tuo sito";
const PAGE_CONTATTI_SLIDE_3_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide26.jpg";
const PAGE_CONTATTI_SLIDE_3_TITOLO = "tutto su misura";
const PAGE_CONTATTI_SLIDE_3_SOTTOTITOLO = "portrai scegliere e personalizzare ogni contenuto";

const PAGE_GIFTCARD_TITOLO = "Seguici"
const PAGE_GIFTCARD_TESTO = "Seguiamo costantemente Convegni del settore perchè conosciamo l'importanza dei continui aggiornamenti professionali. \
Crediamo nella costante crescita delle Professionalità. Riteniamo fondamentale il continuo aggiornamento dei nostri Professionisti. \
Abbiamo a cuore le tue problematiche e garantiamo la migliore soluzione possibile.";
const PAGE_GIFTCARD_SLIDE_1_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide61.jpg";
const PAGE_GIFTCARD_SLIDE_1_TITOLO = "Ogni cosa è scelta con cura";
const PAGE_GIFTCARD_SLIDE_1_SOTTOTITOLO = "Eventi per condividere informazioni e metodologie";
const PAGE_GIFTCARD_SLIDE_2_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide62.jpg";
const PAGE_GIFTCARD_SLIDE_2_TITOLO = "Pubblicazioni per lasciare il segno della nostra professionalità";
const PAGE_GIFTCARD_SLIDE_2_SOTTOTITOLO = "conosciamo l'importanza di condividere esperienze";
const PAGE_GIFTCARD_SLIDE_3_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide63.jpg";
const PAGE_GIFTCARD_SLIDE_3_TITOLO = "Eventi per condividere idee ed emozioni";
const PAGE_GIFTCARD_SLIDE_3_SOTTOTITOLO = "crediamo nell'incontro e nella comunicazione";
                
const PAGE_NEWS_TITOLO = "Seguici"
const PAGE_NEWS_TESTO = "Instauriamo con i nostri clienti una profiqua comunicazione su tutto ciò che può interessare la miglior soluzione praticabile. \
Crediamo nel continuo esame delle problematiche e l'importanza di condividere esperienze importanti. \
Abbiamo a cuore le esigenze dei Clienti e lo loro problematiche.";
const PAGE_NEWS_SLIDE_1_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide64.jpg";
const PAGE_NEWS_SLIDE_1_TITOLO = "condividiamo conoscenze e modi di pensare";
const PAGE_NEWS_SLIDE_1_SOTTOTITOLO = "conosciamo l'importanza di condividere esperienzei";
const PAGE_NEWS_SLIDE_2_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide65.jpg";
const PAGE_NEWS_SLIDE_2_TITOLO = "Ogni informazione è condivisa";
const PAGE_NEWS_SLIDE_2_SOTTOTITOLO = "pubblichiamo approfondimenti";
const PAGE_NEWS_SLIDE_3_URL = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide66.jpg";
const PAGE_NEWS_SLIDE_3_TITOLO = "condividiamo conoscenze e modi di pensare";
const PAGE_NEWS_SLIDE_3_SOTTOTITOLO = "crediamo nell'incontro e nella comunicazione";

// prelievo IMMAGINI CLIENTE da www.shoppingclick.it (non utilizzato)
// =>/home/shouser10/htdocs/www.shoppingclick.it/mpmproject-it/storage-images/site-mpmproject-it
const URL_IMAGES_CUSTOMER = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/";

const URL_IMAGES_CATEGORY = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-0001/categories/";
const URL_IMAGES_PRODUCT = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-0001/products/";

// IMMAGINI CLIENTE da www.shoppingclick.it (non utilizzato)
const URL_IMAGES_CUST_LOGIN = "/login.jpg";
const URL_IMAGES_CUST_CHISIAMO = "/chisiamo.jpg";
const URL_IMAGES_CUST_CONTATTI = "/contatti.jpg";
const URL_IMAGES_CUST_COUPONS = "/coupons.jpg";
const URL_IMAGES_CUST_GIFTCARD = "/giftcard.jpg";


module.exports = { 
  SITE_STYLE_ACTIVE,
  ENABLE_SWITCH_STILE,
  TITLE_PRODUCT,
  URL_IMAGES_SITE,
  URL_IMAGES_CATEGORY,
  URL_IMAGES_PRODUCT,
  CUSTOMER,
  CUSTOMER_CODE,
  CUSTOMER_VIA,
  CUSTOMER_CITTA,
  CUSTOMER_EMAIL,
  CUSTOMER_SLIDE_URL_ARRAY,

  PAGE_LOGIN_TITOLO,
  PAGE_LOGIN_TESTO,
  PAGE_LOGIN_SLIDE_1_URL,
  PAGE_LOGIN_SLIDE_1_TITOLO,
  PAGE_LOGIN_SLIDE_1_SOTTOTITOLO,
  PAGE_LOGIN_SLIDE_2_URL,
  PAGE_LOGIN_SLIDE_2_TITOLO,
  PAGE_LOGIN_SLIDE_2_SOTTOTITOLO,
  PAGE_LOGIN_SLIDE_3_URL,
  PAGE_LOGIN_SLIDE_3_TITOLO,
  PAGE_LOGIN_SLIDE_3_SOTTOTITOLO,

  PAGE_REGISTRA_TITOLO,
  PAGE_REGISTRA_TESTO,
  PAGE_REGISTRA_SLIDE_1_URL,
  PAGE_REGISTRA_SLIDE_1_TITOLO,
  PAGE_REGISTRA_SLIDE_1_SOTTOTITOLO,
  PAGE_REGISTRA_SLIDE_2_URL,
  PAGE_REGISTRA_SLIDE_2_TITOLO,
  PAGE_REGISTRA_SLIDE_2_SOTTOTITOLO,
  PAGE_REGISTRA_SLIDE_3_URL,
  PAGE_REGISTRA_SLIDE_3_TITOLO,
  PAGE_REGISTRA_SLIDE_3_SOTTOTITOLO,

  PAGE_HOME_TITOLO,
  PAGE_HOME_TESTO,
  PAGE_HOME_SLIDE_1_URL,
  PAGE_HOME_SLIDE_1_TITOLO,
  PAGE_HOME_SLIDE_1_SOTTOTITOLO,
  PAGE_HOME_SLIDE_2_URL,
  PAGE_HOME_SLIDE_2_TITOLO,
  PAGE_HOME_SLIDE_2_SOTTOTITOLO,
  PAGE_HOME_SLIDE_3_URL,
  PAGE_HOME_SLIDE_3_TITOLO,
  PAGE_HOME_SLIDE_3_SOTTOTITOLO,

  PAGE_CHISIAMO_TITOLO,
  PAGE_CHISIAMO_TESTO, 
  PAGE_CHISIAMO_SLIDE_1_URL,
  PAGE_CHISIAMO_SLIDE_1_TITOLO,
  PAGE_CHISIAMO_SLIDE_1_SOTTOTITOLO,
  PAGE_CHISIAMO_SLIDE_2_URL,
  PAGE_CHISIAMO_SLIDE_2_TITOLO,
  PAGE_CHISIAMO_SLIDE_2_SOTTOTITOLO,
  PAGE_CHISIAMO_SLIDE_3_URL,
  PAGE_CHISIAMO_SLIDE_3_TITOLO,
  PAGE_CHISIAMO_SLIDE_3_SOTTOTITOLO,

  PAGE_CONTATTI_TITOLO,
  PAGE_CONTATTI_TESTO,
  PAGE_CONTATTI_SLIDE_1_URL,
  PAGE_CONTATTI_SLIDE_1_TITOLO,
  PAGE_CONTATTI_SLIDE_1_SOTTOTITOLO,
  PAGE_CONTATTI_SLIDE_2_URL,
  PAGE_CONTATTI_SLIDE_2_TITOLO,
  PAGE_CONTATTI_SLIDE_2_SOTTOTITOLO,
  PAGE_CONTATTI_SLIDE_3_URL,
  PAGE_CONTATTI_SLIDE_3_TITOLO,
  PAGE_CONTATTI_SLIDE_3_SOTTOTITOLO,  

  PAGE_SITI_TITOLO,
  PAGE_SITI_TESTO,
  PAGE_SITI_SLIDE_1_URL,
  PAGE_SITI_SLIDE_1_TITOLO,
  PAGE_SITI_SLIDE_1_SOTTOTITOLO,
  PAGE_SITI_SLIDE_2_URL,
  PAGE_SITI_SLIDE_2_TITOLO,
  PAGE_SITI_SLIDE_2_SOTTOTITOLO,
  PAGE_SITI_SLIDE_3_URL,
  PAGE_SITI_SLIDE_3_TITOLO,
  PAGE_SITI_SLIDE_3_SOTTOTITOLO,
 
  PAGE_SITIPREZZI_TITOLO,
  PAGE_SITIPREZZI_TESTO,
  PAGE_SITIPREZZI_TESTO1,
  PAGE_SITIPREZZI_PRODOTTO1_DESCR,
  PAGE_SITIPREZZI_PRODOTTO1_DESCR1,
  PAGE_SITIPREZZI_PRODOTTO1_PREZZO,
  PAGE_SITIPREZZI_PRODOTTO2_DESCR,
  PAGE_SITIPREZZI_PRODOTTO2_DESCR1,
  PAGE_SITIPREZZI_PRODOTTO2_PREZZO,
  PAGE_SITIPREZZI_PRODOTTO3_DESCR,
  PAGE_SITIPREZZI_PRODOTTO3_DESCR1,
  PAGE_SITIPREZZI_PRODOTTO3_PREZZO,
  PAGE_SITIPREZZI_PRODOTTO4_DESCR,
  PAGE_SITIPREZZI_PRODOTTO4_DESCR1,
  PAGE_SITIPREZZI_PRODOTTO4_PREZZO,
  PAGE_SITIPREZZI_SLIDE_1_URL,
  PAGE_SITIPREZZI_SLIDE_1_TITOLO,
  PAGE_SITIPREZZI_SLIDE_1_SOTTOTITOLO,
  PAGE_SITIPREZZI_SLIDE_2_URL,
  PAGE_SITIPREZZI_SLIDE_2_TITOLO,
  PAGE_SITIPREZZI_SLIDE_2_SOTTOTITOLO,
  PAGE_SITIPREZZI_SLIDE_3_URL,
  PAGE_SITIPREZZI_SLIDE_3_TITOLO,
  PAGE_SITIPREZZI_SLIDE_3_SOTTOTITOLO,

  PAGE_SITISTILI_TITOLO,
  PAGE_SITISTILI_TESTO,
  PAGE_SITISTILI_TESTO1,
  PAGE_SITISTILI_PRODOTTO1_NOME,
  PAGE_SITISTILI_PRODOTTO1_DESCR,
  PAGE_SITISTILI_PRODOTTO2_NOME,
  PAGE_SITISTILI_PRODOTTO2_DESCR,
  PAGE_SITISTILI_PRODOTTO3_NOME,
  PAGE_SITISTILI_PRODOTTO3_DESCR, 
  PAGE_SITISTILI_PRODOTTO4_NOME,
  PAGE_SITISTILI_PRODOTTO4_DESCR, 
  PAGE_SITISTILI_SLIDE_1_URL,
  PAGE_SITISTILI_SLIDE_1_TITOLO,
  PAGE_SITISTILI_SLIDE_1_SOTTOTITOLO,
  PAGE_SITISTILI_SLIDE_2_URL,
  PAGE_SITISTILI_SLIDE_2_TITOLO,
  PAGE_SITISTILI_SLIDE_2_SOTTOTITOLO,
  PAGE_SITISTILI_SLIDE_3_URL,
  PAGE_SITISTILI_SLIDE_3_TITOLO,
  PAGE_SITISTILI_SLIDE_3_SOTTOTITOLO,

  PAGE_STUDIO_TITOLO,
  PAGE_STUDIO_TESTO,
  PAGE_STUDIO_TESTO1,
  PAGE_STUDIO_PRODOTTO1_DESCR,
  PAGE_STUDIO_PRODOTTO1_DESCR1,
  PAGE_STUDIO_PRODOTTO1_PREZZO,
  PAGE_STUDIO_PRODOTTO2_DESCR,
  PAGE_STUDIO_PRODOTTO2_DESCR1,
  PAGE_STUDIO_PRODOTTO2_PREZZO,
  PAGE_STUDIO_PRODOTTO3_DESCR,
  PAGE_STUDIO_PRODOTTO3_DESCR1,
  PAGE_STUDIO_PRODOTTO3_PREZZO,
  PAGE_STUDIO_PRODOTTO4_DESCR,
  PAGE_STUDIO_PRODOTTO4_DESCR1,
  PAGE_STUDIO_PRODOTTO4_PREZZO,
  PAGE_STUDIO_SLIDE_1_URL,
  PAGE_STUDIO_SLIDE_1_TITOLO,
  PAGE_STUDIO_SLIDE_1_SOTTOTITOLO,
  PAGE_STUDIO_SLIDE_2_URL,
  PAGE_STUDIO_SLIDE_2_TITOLO,
  PAGE_STUDIO_SLIDE_2_SOTTOTITOLO,
  PAGE_STUDIO_SLIDE_3_URL,
  PAGE_STUDIO_SLIDE_3_TITOLO,
  PAGE_STUDIO_SLIDE_3_SOTTOTITOLO,

  PAGE_STUDIOPREZZI_TITOLO,
  PAGE_STUDIOPREZZI_TESTO,
  PAGE_STUDIOPREZZI_TESTO1,
  PAGE_STUDIOPREZZI_PRODOTTO1_DESCR,
  PAGE_STUDIOPREZZI_PRODOTTO1_DESCR1,
  PAGE_STUDIOPREZZI_PRODOTTO1_PREZZO,
  PAGE_STUDIOPREZZI_PRODOTTO2_DESCR,
  PAGE_STUDIOPREZZI_PRODOTTO2_DESCR1,
  PAGE_STUDIOPREZZI_PRODOTTO2_PREZZO,
  PAGE_STUDIOPREZZI_PRODOTTO3_DESCR,
  PAGE_STUDIOPREZZI_PRODOTTO3_DESCR1,
  PAGE_STUDIOPREZZI_PRODOTTO3_PREZZO,
  PAGE_STUDIOPREZZI_PRODOTTO4_DESCR,
  PAGE_STUDIOPREZZI_PRODOTTO4_DESCR1,
  PAGE_STUDIOPREZZI_PRODOTTO4_PREZZO,
  PAGE_STUDIOPREZZI_SLIDE_1_URL,
  PAGE_STUDIOPREZZI_SLIDE_1_TITOLO,
  PAGE_STUDIOPREZZI_SLIDE_1_SOTTOTITOLO,
  PAGE_STUDIOPREZZI_SLIDE_2_URL,
  PAGE_STUDIOPREZZI_SLIDE_2_TITOLO,
  PAGE_STUDIOPREZZI_SLIDE_2_SOTTOTITOLO,
  PAGE_STUDIOPREZZI_SLIDE_3_URL,
  PAGE_STUDIOPREZZI_SLIDE_3_TITOLO,
  PAGE_STUDIOPREZZI_SLIDE_3_SOTTOTITOLO,
  
  PAGE_STUDIOSTILI_TITOLO,
  PAGE_STUDIOSTILI_TESTO,
  PAGE_STUDIOSTILI_TESTO1,
  PAGE_STUDIOSTILI_PRODOTTO1_NOME,
  PAGE_STUDIOSTILI_PRODOTTO1_DESCR,
  PAGE_STUDIOSTILI_PRODOTTO2_NOME,
  PAGE_STUDIOSTILI_PRODOTTO2_DESCR,
  PAGE_STUDIOSTILI_PRODOTTO3_NOME,
  PAGE_STUDIOSTILI_PRODOTTO3_DESCR, 
  PAGE_STUDIOSTILI_PRODOTTO4_NOME,
  PAGE_STUDIOSTILI_PRODOTTO4_DESCR, 
  PAGE_STUDIOSTILI_SLIDE_1_URL,
  PAGE_STUDIOSTILI_SLIDE_1_TITOLO,
  PAGE_STUDIOSTILI_SLIDE_1_SOTTOTITOLO,
  PAGE_STUDIOSTILI_SLIDE_2_URL,
  PAGE_STUDIOSTILI_SLIDE_2_TITOLO,
  PAGE_STUDIOSTILI_SLIDE_2_SOTTOTITOLO,
  PAGE_STUDIOSTILI_SLIDE_3_URL,
  PAGE_STUDIOSTILI_SLIDE_3_TITOLO,
  PAGE_STUDIOSTILI_SLIDE_3_SOTTOTITOLO,
  
  PAGE_PLANNER_TITOLO,
  PAGE_PLANNER_TESTO,
  PAGE_PLANNER_TESTO1,
  PAGE_PLANNER_PRODOTTO1_DESCR,
  PAGE_PLANNER_PRODOTTO1_DESCR1,
  PAGE_PLANNER_PRODOTTO1_PREZZO,
  PAGE_PLANNER_PRODOTTO2_DESCR,
  PAGE_PLANNER_PRODOTTO2_DESCR1,
  PAGE_PLANNER_PRODOTTO2_PREZZO,
  PAGE_PLANNER_PRODOTTO3_DESCR,
  PAGE_PLANNER_PRODOTTO3_DESCR1,
  PAGE_PLANNER_PRODOTTO3_PREZZO,
  PAGE_PLANNER_PRODOTTO4_DESCR,
  PAGE_PLANNER_PRODOTTO4_DESCR1,
  PAGE_PLANNER_PRODOTTO4_PREZZO,
  PAGE_PLANNER_SLIDE_1_URL,
  PAGE_PLANNER_SLIDE_1_TITOLO,
  PAGE_PLANNER_SLIDE_1_SOTTOTITOLO,
  PAGE_PLANNER_SLIDE_2_URL,
  PAGE_PLANNER_SLIDE_2_TITOLO,
  PAGE_PLANNER_SLIDE_2_SOTTOTITOLO,
  PAGE_PLANNER_SLIDE_3_URL,
  PAGE_PLANNER_SLIDE_3_TITOLO,
  PAGE_PLANNER_SLIDE_3_SOTTOTITOLO,

  PAGE_PLANNERPREZZI_TITOLO,
  PAGE_PLANNERPREZZI_TESTO,
  PAGE_PLANNERPREZZI_TESTO1,
  PAGE_PLANNERPREZZI_PRODOTTO1_DESCR,
  PAGE_PLANNERPREZZI_PRODOTTO1_DESCR1,
  PAGE_PLANNERPREZZI_PRODOTTO1_PREZZO,
  PAGE_PLANNERPREZZI_PRODOTTO2_DESCR,
  PAGE_PLANNERPREZZI_PRODOTTO2_DESCR1,
  PAGE_PLANNERPREZZI_PRODOTTO2_PREZZO,
  PAGE_PLANNERPREZZI_PRODOTTO3_DESCR,
  PAGE_PLANNERPREZZI_PRODOTTO3_DESCR1,
  PAGE_PLANNERPREZZI_PRODOTTO3_PREZZO,
  PAGE_PLANNERPREZZI_PRODOTTO4_DESCR,
  PAGE_PLANNERPREZZI_PRODOTTO4_DESCR1,
  PAGE_PLANNERPREZZI_PRODOTTO4_PREZZO,
  PAGE_PLANNERPREZZI_SLIDE_1_URL,
  PAGE_PLANNERPREZZI_SLIDE_1_TITOLO,
  PAGE_PLANNERPREZZI_SLIDE_1_SOTTOTITOLO,
  PAGE_PLANNERPREZZI_SLIDE_2_URL,
  PAGE_PLANNERPREZZI_SLIDE_2_TITOLO,
  PAGE_PLANNERPREZZI_SLIDE_2_SOTTOTITOLO,
  PAGE_PLANNERPREZZI_SLIDE_3_URL,
  PAGE_PLANNERPREZZI_SLIDE_3_TITOLO,
  PAGE_PLANNERPREZZI_SLIDE_3_SOTTOTITOLO,
  
  PAGE_PLANNERSTILI_TITOLO,
  PAGE_PLANNERSTILI_TESTO,
  PAGE_PLANNERSTILI_TESTO1,
  PAGE_PLANNERSTILI_PRODOTTO1_NOME,
  PAGE_PLANNERSTILI_PRODOTTO1_DESCR,
  PAGE_PLANNERSTILI_PRODOTTO2_NOME,
  PAGE_PLANNERSTILI_PRODOTTO2_DESCR,
  PAGE_PLANNERSTILI_PRODOTTO3_NOME,
  PAGE_PLANNERSTILI_PRODOTTO3_DESCR, 
  PAGE_PLANNERSTILI_PRODOTTO4_NOME,
  PAGE_PLANNERSTILI_PRODOTTO4_DESCR, 
  PAGE_PLANNERSTILI_SLIDE_1_URL,
  PAGE_PLANNERSTILI_SLIDE_1_TITOLO,
  PAGE_PLANNERSTILI_SLIDE_1_SOTTOTITOLO,
  PAGE_PLANNERSTILI_SLIDE_2_URL,
  PAGE_PLANNERSTILI_SLIDE_2_TITOLO,
  PAGE_PLANNERSTILI_SLIDE_2_SOTTOTITOLO,
  PAGE_PLANNERSTILI_SLIDE_3_URL,
  PAGE_PLANNERSTILI_SLIDE_3_TITOLO,
  PAGE_PLANNERSTILI_SLIDE_3_SOTTOTITOLO,
  
  PAGE_HOTEL_TITOLO,
  PAGE_HOTEL_TESTO,
  PAGE_HOTEL_TESTO1,
  PAGE_HOTEL_PRODOTTO1_DESCR,
  PAGE_HOTEL_PRODOTTO1_DESCR1,
  PAGE_HOTEL_PRODOTTO1_PREZZO,
  PAGE_HOTEL_PRODOTTO2_DESCR,
  PAGE_HOTEL_PRODOTTO2_DESCR1,
  PAGE_HOTEL_PRODOTTO2_PREZZO,
  PAGE_HOTEL_PRODOTTO3_DESCR,
  PAGE_HOTEL_PRODOTTO3_DESCR1,
  PAGE_HOTEL_PRODOTTO3_PREZZO,
  PAGE_HOTEL_PRODOTTO4_DESCR,
  PAGE_HOTEL_PRODOTTO4_DESCR1,
  PAGE_HOTEL_PRODOTTO4_PREZZO,
  PAGE_HOTEL_SLIDE_1_URL,
  PAGE_HOTEL_SLIDE_1_TITOLO,
  PAGE_HOTEL_SLIDE_1_SOTTOTITOLO,
  PAGE_HOTEL_SLIDE_2_URL,
  PAGE_HOTEL_SLIDE_2_TITOLO,
  PAGE_HOTEL_SLIDE_2_SOTTOTITOLO,
  PAGE_HOTEL_SLIDE_3_URL,
  PAGE_HOTEL_SLIDE_3_TITOLO,
  PAGE_HOTEL_SLIDE_3_SOTTOTITOLO,

  PAGE_HOTELPREZZI_TITOLO,
  PAGE_HOTELPREZZI_TESTO,
  PAGE_HOTELPREZZI_TESTO1,
  PAGE_HOTELPREZZI_PRODOTTO1_DESCR,
  PAGE_HOTELPREZZI_PRODOTTO1_DESCR1,
  PAGE_HOTELPREZZI_PRODOTTO1_PREZZO,
  PAGE_HOTELPREZZI_PRODOTTO2_DESCR,
  PAGE_HOTELPREZZI_PRODOTTO2_DESCR1,
  PAGE_HOTELPREZZI_PRODOTTO2_PREZZO,
  PAGE_HOTELPREZZI_PRODOTTO3_DESCR,
  PAGE_HOTELPREZZI_PRODOTTO3_DESCR1,
  PAGE_HOTELPREZZI_PRODOTTO3_PREZZO,
  PAGE_HOTELPREZZI_PRODOTTO4_DESCR,
  PAGE_HOTELPREZZI_PRODOTTO4_DESCR1,
  PAGE_HOTELPREZZI_PRODOTTO4_PREZZO,
  PAGE_HOTELPREZZI_SLIDE_1_URL,
  PAGE_HOTELPREZZI_SLIDE_1_TITOLO,
  PAGE_HOTELPREZZI_SLIDE_1_SOTTOTITOLO,
  PAGE_HOTELPREZZI_SLIDE_2_URL,
  PAGE_HOTELPREZZI_SLIDE_2_TITOLO,
  PAGE_HOTELPREZZI_SLIDE_2_SOTTOTITOLO,
  PAGE_HOTELPREZZI_SLIDE_3_URL,
  PAGE_HOTELPREZZI_SLIDE_3_TITOLO,
  PAGE_HOTELPREZZI_SLIDE_3_SOTTOTITOLO,

  PAGE_HOTELSTILI_TITOLO,
  PAGE_HOTELSTILI_TESTO,
  PAGE_HOTELSTILI_TESTO1,
  PAGE_HOTELSTILI_PRODOTTO1_NOME,
  PAGE_HOTELSTILI_PRODOTTO1_DESCR,
  PAGE_HOTELSTILI_PRODOTTO2_NOME,
  PAGE_HOTELSTILI_PRODOTTO2_DESCR,
  PAGE_HOTELSTILI_PRODOTTO3_NOME,
  PAGE_HOTELSTILI_PRODOTTO3_DESCR, 
  PAGE_HOTELSTILI_PRODOTTO4_NOME,
  PAGE_HOTELSTILI_PRODOTTO4_DESCR, 
  PAGE_HOTELSTILI_SLIDE_1_URL,
  PAGE_HOTELSTILI_SLIDE_1_TITOLO,
  PAGE_HOTELSTILI_SLIDE_1_SOTTOTITOLO,
  PAGE_HOTELSTILI_SLIDE_2_URL,
  PAGE_HOTELSTILI_SLIDE_2_TITOLO,
  PAGE_HOTELSTILI_SLIDE_2_SOTTOTITOLO,
  PAGE_HOTELSTILI_SLIDE_3_URL,
  PAGE_HOTELSTILI_SLIDE_3_TITOLO,
  PAGE_HOTELSTILI_SLIDE_3_SOTTOTITOLO,

  PAGE_ECOMMERCE_TITOLO,
  PAGE_ECOMMERCE_TESTO,
  PAGE_ECOMMERCE_SLIDE_1_URL,
  PAGE_ECOMMERCE_SLIDE_1_TITOLO,
  PAGE_ECOMMERCE_SLIDE_1_SOTTOTITOLO,
  PAGE_ECOMMERCE_SLIDE_2_URL,
  PAGE_ECOMMERCE_SLIDE_2_TITOLO,
  PAGE_ECOMMERCE_SLIDE_2_SOTTOTITOLO,
  PAGE_ECOMMERCE_SLIDE_3_URL,
  PAGE_ECOMMERCE_SLIDE_3_TITOLO,
  PAGE_ECOMMERCE_SLIDE_3_SOTTOTITOLO,

  PAGE_ECOMMERCEPREZZI_TITOLO,
  PAGE_ECOMMERCEPREZZI_TESTO,
  PAGE_ECOMMERCEPREZZI_TESTO1,
  PAGE_ECOMMERCEPREZZI_PRODOTTO1_DESCR,
  PAGE_ECOMMERCEPREZZI_PRODOTTO1_PREZZO,
  PAGE_ECOMMERCEPREZZI_PRODOTTO2_DESCR,
  PAGE_ECOMMERCEPREZZI_PRODOTTO2_PREZZO,
  PAGE_ECOMMERCEPREZZI_PRODOTTO3_DESCR,
  PAGE_ECOMMERCEPREZZI_PRODOTTO3_PREZZO,
  PAGE_ECOMMERCEPREZZI_SLIDE_1_URL,
  PAGE_ECOMMERCEPREZZI_SLIDE_1_TITOLO,
  PAGE_ECOMMERCEPREZZI_SLIDE_1_SOTTOTITOLO,
  PAGE_ECOMMERCEPREZZI_SLIDE_2_URL,
  PAGE_ECOMMERCEPREZZI_SLIDE_2_TITOLO,
  PAGE_ECOMMERCEPREZZI_SLIDE_2_SOTTOTITOLO,
  PAGE_ECOMMERCEPREZZI_SLIDE_3_URL,
  PAGE_ECOMMERCEPREZZI_SLIDE_3_TITOLO,
  PAGE_ECOMMERCEPREZZI_SLIDE_3_SOTTOTITOLO,

  PAGE_ECOMMERCESTILI_TITOLO,
  PAGE_ECOMMERCESTILI_TESTO,
  PAGE_ECOMMERCESTILI_TESTO1,
  PAGE_ECOMMERCESTILI_PRODOTTO1_NOME,
  PAGE_ECOMMERCESTILI_PRODOTTO1_DESCR,
  PAGE_ECOMMERCESTILI_PRODOTTO2_NOME,
  PAGE_ECOMMERCESTILI_PRODOTTO2_DESCR,
  PAGE_ECOMMERCESTILI_PRODOTTO3_NOME,
  PAGE_ECOMMERCESTILI_PRODOTTO3_DESCR, 
  PAGE_ECOMMERCESTILI_SLIDE_1_URL,
  PAGE_ECOMMERCESTILI_SLIDE_1_TITOLO,
  PAGE_ECOMMERCESTILI_SLIDE_1_SOTTOTITOLO,
  PAGE_ECOMMERCESTILI_SLIDE_2_URL,
  PAGE_ECOMMERCESTILI_SLIDE_2_TITOLO,
  PAGE_ECOMMERCESTILI_SLIDE_2_SOTTOTITOLO,
  PAGE_ECOMMERCESTILI_SLIDE_3_URL,
  PAGE_ECOMMERCESTILI_SLIDE_3_TITOLO,
  PAGE_ECOMMERCESTILI_SLIDE_3_SOTTOTITOLO,

  PAGE_LANDING_TITOLO,
  PAGE_LANDING_TESTO,
  PAGE_LANDING_SLIDE_1_URL,
  PAGE_LANDING_SLIDE_1_TITOLO,
  PAGE_LANDING_SLIDE_1_SOTTOTITOLO,
  PAGE_LANDING_SLIDE_2_URL,
  PAGE_LANDING_SLIDE_2_TITOLO,
  PAGE_LANDING_SLIDE_2_SOTTOTITOLO,
  PAGE_LANDING_SLIDE_3_URL,
  PAGE_LANDING_SLIDE_3_TITOLO,
  PAGE_LANDING_SLIDE_3_SOTTOTITOLO,

  PAGE_GIFTCARD_TITOLO,
  PAGE_GIFTCARD_TESTO,
  PAGE_GIFTCARD_SLIDE_1_URL,
  PAGE_GIFTCARD_SLIDE_1_TITOLO,
  PAGE_GIFTCARD_SLIDE_1_SOTTOTITOLO,
  PAGE_GIFTCARD_SLIDE_2_URL,
  PAGE_GIFTCARD_SLIDE_2_TITOLO,
  PAGE_GIFTCARD_SLIDE_2_SOTTOTITOLO,
  PAGE_GIFTCARD_SLIDE_3_URL,
  PAGE_GIFTCARD_SLIDE_3_TITOLO,
  PAGE_GIFTCARD_SLIDE_3_SOTTOTITOLO,
   
  PAGE_NEWS_TITOLO,
  PAGE_NEWS_TESTO,
  PAGE_NEWS_SLIDE_1_URL,
  PAGE_NEWS_SLIDE_1_TITOLO,
  PAGE_NEWS_SLIDE_1_SOTTOTITOLO,
  PAGE_NEWS_SLIDE_2_URL,
  PAGE_NEWS_SLIDE_2_TITOLO,
  PAGE_NEWS_SLIDE_2_SOTTOTITOLO,
  PAGE_NEWS_SLIDE_3_URL,
  PAGE_NEWS_SLIDE_3_TITOLO,
  PAGE_NEWS_SLIDE_3_SOTTOTITOLO,
  
  URL_IMAGES_CUSTOMER,
  URL_IMAGES_CUST_LOGIN,
  URL_IMAGES_CUST_CHISIAMO,
  URL_IMAGES_CUST_CONTATTI,
  URL_IMAGES_CUST_COUPONS,
  URL_IMAGES_CUST_GIFTCARD
};
