import {
  Navbar,
  Nav,
  Container,
  NavDropdown,
  Badge,
  Form,
  DropdownButton,
  Dropdown,
  Button,
  InputGroup,
} from "react-bootstrap";

import { LinkContainer } from "react-router-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../redux/actions/userActions.js";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getCategories } from "../redux/actions/categoryActions.js";
import socketIOClient from "socket.io-client";
import { setChatRooms, setSocket, setMessageReceived, removeChatRoom } from "../redux/actions/chatActions.js";

const config = require("../constants/constants.js")

const HeaderComponent1styleA = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userRegisterLogin);
  const itemsCount = useSelector((state) => state.cart.itemsCount);
  const { categories } = useSelector((state) => state.getCategories);
  const { messageReceived } = useSelector((state) => state.adminChat);

  const [searchCategoryToggle, setSearchCategoryToggle] = useState("Tutte le categorie");
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();

//  const url_site_image_logo = "../logoMyshop.png"; 
  const url_site_image_logo = config.URL_IMAGES_SITE+"logoMyShop.png";
  const url_site_image_shopnow = config.URL_IMAGES_SITE+"shop-now.png";
  const url_customer_image_logo = config.URL_IMAGES_CUSTOMER+"logo-cust"+config.CUSTOMER_CODE+".png";  // logo-cust0001.png 

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const submitHandler = (e) => {
     if (e.keyCode && e.keyCode !== 13) return;
     e.preventDefault();
     if (searchQuery.trim()) {
         if (searchCategoryToggle === "Tutte le categorie") {
             navigate(`/product-list/search/${searchQuery}`);
         } else {
             navigate(`/product-list/category/${searchCategoryToggle.replaceAll("/", ",")}/search/${searchQuery}`);
         }
     } else if (searchCategoryToggle !== "Tutte le categorie") {
         navigate(`/product-list/category/${searchCategoryToggle.replaceAll("/", ",")}`);
     } else {
         navigate("/product-list");
     }
  }

  useEffect(() => {
      if (userInfo.isAdmin) {
          var audio = new Audio("/audio/chat-msg.mp3");
          const socket = socketIOClient();
          socket.emit("admin connected with server", "Admin" + Math.floor(Math.random() * 1000000000000));
          socket.on("server sends message from client to admin", ({user, message}) => {
              dispatch(setSocket(socket));
        //   let chatRooms = {
        //     fddf54gfgfSocketID: [{ "client": "dsfdf" }, { "client": "dsfdf" }, { "admin": "dsfdf" }],
        //   };
            dispatch(setChatRooms(user, message));      
             dispatch(setMessageReceived(true));  
             audio.play();
          })
          socket.on("disconnected", ({reason, socketId}) => {
            //   console.log(socketId, reason)
            dispatch(removeChatRoom(socketId));
          })
          return () => socket.disconnect();
      }
  },[userInfo.isAdmin])

  return (
    <Navbar collapseOnSelect expand="lg" bg="secondary" variant="dark">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand href="/">
            <img src={url_customer_image_logo} alt="bug" height={40} />       
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </Navbar.Brand>
        </LinkContainer>
        
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
       
        <Navbar.Collapse id="responsive-navbar-nav">     

          <Navbar expand="sm" bg="secondary" variant="primary" >
          
            <Nav>
              <LinkContainer to="/home-menu">
                      <Nav.Link>Home</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/chi-siamo">
                      <Nav.Link>Chi siamo</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/contatti">
                      <Nav.Link>Contatti</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/ecommerce">
                      <Nav.Link>Staff</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/siti">
                      <Nav.Link>Aree competenza</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/progetti">
                      <Nav.Link>Progetti</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/eventi">
                      <Nav.Link>Eventi</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/news">
                      <Nav.Link>News</Nav.Link>
              </LinkContainer>
            </Nav>

          </Navbar> 

          <LinkContainer to="/product-list">
            <Navbar.Brand href="/product-list">
              <img src={url_site_image_shopnow} alt="bug" height={40} />       
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </Navbar.Brand>
          </LinkContainer>

          <Nav>

            {userInfo.isAdmin ? (
              <LinkContainer to="/admin/orders">
                <Nav.Link>
                  Admin
                  {messageReceived && <span className="position-absolute top-1 start-10 translate-middle p-2 bg-danger border border-light rounded-circle"></span>}
                  
                </Nav.Link>
              </LinkContainer>
            ) : userInfo.name && !userInfo.isAdmin ? (
              <NavDropdown
                title={`${userInfo.name} ${userInfo.lastName}`}
                id="collasible-nav-dropdown"
              >
                <NavDropdown.Item
                  eventKey="/user/my-orders"
                  as={Link}
                  to="/user/my-orders"
                >
                  Ordini
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="/user" as={Link} to="/user">
                  Profilo
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => dispatch(logout())}>
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <>
                <LinkContainer to="/login">
                  <Nav.Link>Login</Nav.Link>
                </LinkContainer>
              </>
            )}

          </Nav>
          
        </Navbar.Collapse>

      </Container>

    </Navbar>

);
};

export default HeaderComponent1styleA;
