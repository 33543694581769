import { Container, Col } from "react-bootstrap";

import React from "react";
import {
    Box,
    FooterContainer,
    Row,
    Column,
    FooterLink,
    Heading,
} from "./FooterStylesStyleA";
 

const FooterComponentStyleA = () => {
  return (
    <Box>
        <FooterContainer>
            <Row>
                <Column>
                    <Heading>MPM PROJECT&trade;</Heading>
                    <FooterLink>
                        <i>
                            <span
                                style={{
                                    color: "white"
                                }}
                            >
                            Copyright &copy; 2024 MPM project
                            </span>
                        </i>
                    </FooterLink>
                    <FooterLink>
                        <i>
                            <span
                                style={{
                                    color: "white",
                                }}
                            >
                            WEB AGENCY
                            </span>
                        </i>
                    </FooterLink>
                </Column>
                <Column>
                    <Heading>About Us</Heading>
                    <FooterLink href="#">
                      Studio solutions
                    </FooterLink>
                    <FooterLink href="https://www.mpmproject.it">
                        <i>
                            <span
                                style={{
                                    color: "yellow",
                                }}
                            >
                            www.mpmproject.it
                            </span>
                        </i>
                    </FooterLink>
                </Column>
                <Column>
                    <Heading>Contattaci</Heading>
                    <FooterLink href="#">
                        info@mpmproject.it
                    </FooterLink>
                </Column>
                <Column>
                    <Heading>Social Media</Heading>
                    <FooterLink href="#">
                        <i >
                            <span
                                style={{
                                    marginLeft: "20px",
                                }}
                            >
                                Facebook
                            </span>
                        </i>
                    </FooterLink>
                    <FooterLink href="#">
                        <i>
                            <span
                                style={{
                                    marginLeft: "20px",
                                }}
                            >
                            Instagram
                            </span>                           
                        </i>
                    </FooterLink>
                </Column>
            </Row>
        </FooterContainer>
    </Box>
  );
};


export default FooterComponentStyleA;
